import { Injectable, inject } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

import type { Results } from '@prisma/client';
import {
  GridData,
  ResultWithRelations,
  PrismaDisciplineImportType as DisciplineImportType,
  PrismaValidation as Validation,
} from '@beathletics/api-interfaces';
import {
  CODES_ALL_RACES,
  CODES_DISCIPLINES_WITH_TRIALS,
  CODES_HIGH_JUMPS,
  findBestPerfFromResult,
  VALIDATION_STATUS,
  verifyResultPrecision,
} from '@beathletics/utils-shared';

@Injectable({
  providedIn: 'root',
})
export class ResultService {
  private transloco = inject(TranslocoService);

  constructor() {
    const transloco = this.transloco;

    transloco.setActiveLang('fr');
  }
}

export const processResults = (results: Results[]) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (results && results.length > 0) {
    return results.map((r) => ({
      ...r,
      grid: {
        validation: getValidationData(r.validation),
        category: getCategoryData(r),
        athlete: getAthleteData(r).name,
        bib: getAthleteData(r).bib,
        club: getAthleteData(r).club,
        discipline: getDisciplineData(r),
        heat: getHeatData(r),
        result: getResultData(r),
        position: getPositionData(r),
        wind: getWindData(r),
        info: getInfoData(r),
        comment: getCommentData(r),
      },
    }));
  } else return [];
};

function getValidationData(status: Validation): GridData {
  return {
    displayValue: 'STATUS_' + status,
    sortValue: status,
  };
}

function getCategoryData(res: Results): GridData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cat = res?.athleteCategory || (res?.eventCategory as any);
  return {
    sortValue: cat.name,
  };
}

function getAthleteData(res: ResultWithRelations): {
  name: GridData;
  bib: GridData;
  club: GridData;
} {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rawAthletes = res?.athletes && res.athletes.length > 0 ? res.athletes : (res?.rawAthletes as any[]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result = res?.result as any;
  const athleteData = {
    name: {
      displayValue: '',
      sortValue: '',
    },
    bib: {
      displayValue: '',
      sortValue: '',
    },
    club: {
      displayValue: '',
      sortValue: '',
    },
  };
  if (res.type === DisciplineImportType.relay) {
    athleteData.name.displayValue = result?.teamName + '<br />';
    athleteData.name.sortValue = result?.teamName + ' ';
    athleteData.bib.displayValue = '<br />';
    athleteData.club.displayValue = '<br />';
    for (const [i, athlete] of rawAthletes.entries()) {
      athleteData.name.displayValue += `${athlete?.order || i + 1}. ${
        athlete.firstname
      } ${athlete.lastname}${i !== rawAthletes.length - 1 ? '<br />' : ''}`;
      athleteData.name.sortValue += `| ${athlete.firstname} ${athlete.lastname}`;
      athleteData.bib.displayValue += `${
        athlete?.dossard || athlete?.bib || '-'
      } ${i !== rawAthletes.length - 1 ? '<br />' : ''}`;
      athleteData.bib.sortValue += `| ${athlete?.bib}`;
      athleteData.club.displayValue += `${
        athlete?.organization?.abbr || athlete?.club?.abbreviation
      } ${i !== rawAthletes.length - 1 ? '<br />' : ''}`;
      athleteData.club.sortValue += `| ${athlete?.organization?.abbr || athlete?.club?.abbreviation}`;
    }
  } else {
    const val = rawAthletes?.[0]?.name || rawAthletes?.[0]?.firstname + ' ' + rawAthletes?.[0]?.lastname;
    athleteData.name.displayValue = val;
    athleteData.name.sortValue = val;
    athleteData.bib.displayValue = rawAthletes?.[0]?.dossard || rawAthletes?.[0]?.bib || '-';
    athleteData.bib.sortValue = rawAthletes?.[0]?.dossard || rawAthletes?.[0]?.bib || '-';
    athleteData.club.displayValue = rawAthletes?.[0]?.organization?.abbr || rawAthletes?.[0]?.club?.abbreviation;
    athleteData.club.sortValue = rawAthletes?.[0]?.organization?.abbr || rawAthletes?.[0]?.club?.abbreviation;
  }
  return athleteData;
}

function getDisciplineData(res: ResultWithRelations): GridData {
  let displayValue = res?.eventType?.name_fr || res.name;
  let sortValue = res?.eventType?.name_fr || res.name;
  if (res.type === DisciplineImportType.combined && res?.combinedEvents && (res?.combinedEvents as any[]).length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const events = res?.combinedEvents as any[];
    if (events && events.length > 0) {
      displayValue += '<br />';
      sortValue += ' ';
      for (const [i, ev] of events.entries()) {
        displayValue += `${ev?.order}. ${ev?.name}${i !== events.length - 1 ? '<br />' : ''}`;
        sortValue += `| ${ev?.name}`;
      }
    }
  }
  return { displayValue, sortValue };
}

function getHeatData(res: Results): GridData {
  let displayValue = '';
  let sortValue = '';
  if (res.type === DisciplineImportType.combined && res?.combinedEvents && Array.isArray(typeof res?.combinedEvents)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const events = res?.combinedEvents as any[];
    displayValue = '<br />';
    for (const [i, ev] of events.entries()) {
      displayValue += `${ev?.heat?.order}${i !== events.length - 1 ? '<br />' : ''}`;
      sortValue += `${ev?.heat?.order}`;
    }
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const heat = res?.heat as any;
    displayValue = heat?.order;
    sortValue = heat?.order;
  }
  return { displayValue, sortValue };
}

function getResultData(res: ResultWithRelations): GridData {
  // TODO : check ourself if the result can be homologized (best result + wind < 2)
  let displayValue = '';
  let sortValue = '';
  if (res.type === DisciplineImportType.combined && res?.combinedEvents && (res?.combinedEvents as any[]).length > 0) {
    displayValue = 'TOTAL = ' + res?.pointsTotal + '<br />';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const events = res?.combinedEvents as any[];
    for (const [i, ev] of events.entries()) {
      if (ev.result?.results?.length > 0) {
        const bestPerf = findBestPerfFromResult(ev);
        if (bestPerf) {
          const precision = (ev?.eventType?.resultType?.precision || 100).toString();
          displayValue += `${
            bestPerf?.metavalue !== ''
              ? verifyResultPrecision(bestPerf.metavalue, precision.length - 1)
              : verifyResultPrecision(bestPerf.value, precision.length - 1)
          }${
            ev?.validation ? ' (' + VALIDATION_STATUS['' + ev.validation] + ')' : ''
          }${i !== events.length - 1 ? '<br />' : ''}`;
        }
      }
    }
    sortValue += res?.pointsTotal;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const eventType = res?.eventType || (res?.resultEventType as any);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _res = res?.result as any;
    if (
      CODES_DISCIPLINES_WITH_TRIALS.includes(+eventType?.national_code) ||
      CODES_DISCIPLINES_WITH_TRIALS.includes(+eventType?.nationalcode)
    ) {
      if (CODES_HIGH_JUMPS.includes(+eventType?.national_code) || CODES_HIGH_JUMPS.includes(+eventType?.nationalcode)) {
        if (_res?.alternative_code) {
          displayValue = _res?.alternative_code;
          sortValue = _res?.alternative_code;
        } else if (_res?.results[0]?.metavalue) {
          displayValue = formatHighJumpResultsWithTrials(_res?.results);
          sortValue += _res?.results[_res?.results.length - 1]?.metavalue;
        } else {
          displayValue = _res?.results[0]?.score_distance;
          sortValue += _res?.results[0]?.score_distance;
        }
      } else {
        if (_res?.results[0]?.value) {
          displayValue = formatJumpResultsWithWind(_res?.results);
          sortValue += _res?.results[0]?.value;
        } else {
          displayValue = _res?.results[0]?.score_distance;
          sortValue += _res?.results[0]?.score_distance;
        }
      }
    } else {
      if (CODES_ALL_RACES.includes(+eventType?.national_code) || CODES_ALL_RACES.includes(+eventType?.national_code)) {
        if (_res?.results?.['@_bestresult']) {
          _res.results = _res?.results.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (res: any) => res['@_bestresult'] === '1',
          );
        }
      }
      if (_res && _res?.results?.length > 0) {
        for (const [i, res] of _res.results.entries()) {
          let resValue: string | number = '';
          if (res !== null) {
            resValue = res.value || res.time || res.score_distance || res.place || res.result_value;
          }
          if (res?.['@_bestresult'] === '1' && _res.results.length > 1) {
            if (res.value || +res.value === 0) {
              displayValue += `<strong>${
                typeof res.value === 'number' ? res.value.toFixed(2) : res.value
              }</strong>${i !== _res.results.length - 1 ? '<br />' : ''}`;
              sortValue += typeof res.value === 'number' ? res.value.toFixed(2) : res.value;
            } else
              displayValue += `<strong>${
                typeof resValue === 'number' ? resValue.toFixed(2) : resValue
              }</strong>${i !== _res.results.length - 1 ? '<br />' : ''}`;
            sortValue += typeof resValue === 'number' ? resValue.toFixed(2) : resValue;
          } else {
            if (res?.value || +res?.value === 0) {
              displayValue += `${
                typeof res.value === 'number' ? res.value.toFixed(2) : res.value
              }${i !== _res.results.length - 1 ? '<br />' : ''}`;
              sortValue += res.value === 'number' ? res.value.toFixed(2) : res.value;
            } else
              displayValue += `${
                typeof resValue === 'number' ? resValue.toFixed(2) : resValue
              }${i !== _res.results.length - 1 ? '<br />' : ''}`;
            sortValue += typeof resValue === 'number' ? resValue.toFixed(2) : resValue;
          }
        }
      }
    }
  }
  return { displayValue, sortValue };
}

function getPositionData(res: Results): GridData {
  let displayValue = '';
  let sortValue = '';
  if (res.type === DisciplineImportType.combined && res?.combinedEvents && (res?.combinedEvents as any[]).length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const events = res?.combinedEvents as any[];
    displayValue = '<br />';
    for (const [i, ev] of events.entries()) {
      displayValue += `${ev.result.currentOrder ? ev.result.currentOrder : ''}${
        i !== events.length - 1 ? '<br />' : ''
      }`;
      sortValue += `${ev.result.currentOrder ? ev.result.currentOrder : 0}`;
    }
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result = res?.result as any;
    displayValue = result?.currentOrder || result?.results?.[0]?.place;
    sortValue = result?.currentOrder || result?.results?.[0]?.place;
  }
  return { displayValue, sortValue };
}

function getWindData(res: Results): GridData {
  let displayValue = '';
  let sortValue = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const eventType = res?.resultEventType as any;
  if (eventType?.windmode !== 'N') {
    if (
      res.type === DisciplineImportType.combined &&
      res?.combinedEvents &&
      (res?.combinedEvents as any[]).length > 0
    ) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const events = res?.combinedEvents as any[];
      displayValue = '<br />';
      for (const [i, ev] of events.entries()) {
        displayValue += `${'' + ev.result?.results?.[0]?.wind || ''}${i !== events.length - 1 ? '<br />' : ''}`;
        sortValue += `${'' + ev.result?.results?.[0]?.wind || ''}`;
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const result = res?.result as any;
      for (const [i, _res] of result.results.entries()) {
        displayValue += `${'' + _res?.wind || ''}${i !== result.results.length - 1 ? '<br />' : ''}`;
        sortValue += `${'' + _res?.wind || ''} `;
      }
    }
  }
  return { displayValue, sortValue };
}

function getInfoData(res: Results): GridData {
  let displayValue = '';
  let sortValue = '';
  if (res.type === DisciplineImportType.combined && res?.combinedEvents && (res?.combinedEvents as any[]).length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const events = res?.combinedEvents as any[];
    displayValue = '<br />';
    for (const [i, ev] of events.entries()) {
      displayValue += formatResultInfo(ev.result?.results?.[0]?.info) + (i !== events.length - 1 ? '<br />' : '');
      sortValue += formatResultInfo(ev.result?.results?.[0]?.info) + ' ';
    }
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _res = res?.result as any;
    if (_res && _res?.results && _res?.results.length > 0) {
      for (const [i, r] of _res.results.entries()) {
        displayValue += formatResultInfo(r?.info) + (i !== _res.results.length - 1 ? '<br />' : '');
        sortValue += formatResultInfo(r?.info) + ' ';
      }
    }
  }
  return { displayValue, sortValue };
}

function getCommentData(res: Results) {
  let displayValue = '';
  let sortValue = '';
  if (res.type === DisciplineImportType.combined && res?.combinedEvents && (res?.combinedEvents as any[]).length > 0) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const events = res?.combinedEvents as any[];
    displayValue = '<br />';
    for (const [i, ev] of events.entries()) {
      displayValue += (ev?.comment || '') + (i !== events.length - 1 ? '<br />' : '');
      sortValue += (ev?.comment || '') + ' ';
    }
  } else {
    displayValue = res?.comment || '';
    sortValue = res?.comment || '';
  }
  return { displayValue, sortValue };
}

function formatResultInfo(info: string) {
  if (info) {
    if (info.includes('PHF')) {
      const infoArr = info.split(' ');
      let _info = '';
      for (let i = 0; i < infoArr.length; i++) {
        if (i !== 0 && i !== infoArr.length - 1) _info += infoArr[i] + ' ';
        if (i !== 0 && i === infoArr.length - 1) _info += infoArr[i];
      }
      return _info;
    } else return info;
  } else return '';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatHighJumpResultsWithTrials(results: any[]) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const heightValues: any[] = [];
  let bestHeight = '';
  let htmlString = '';
  for (const res of results) {
    if (!heightValues.includes(res.metavalue)) heightValues.push(res.metavalue);
    if (res?.['@_bestresult'] === '1') bestHeight = res.metavalue;
  }
  for (let i = 0; i < heightValues.length; i++) {
    let value = '';
    let line = '';
    const val = typeof heightValues[i] === 'number' ? heightValues[i].toFixed(2) : heightValues[i];
    value += val + ' (';
    for (const res of results) {
      if (res.metavalue === heightValues[i]) {
        value += res.value;
      }
    }
    line += value + ')';
    if (heightValues[i] === bestHeight) {
      line = `<strong>${line}</strong>`;
    }
    if (i !== heightValues.length - 1) {
      line += '<br />';
    }
    htmlString += line;
  }
  return htmlString;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function formatJumpResultsWithWind(results: any[]) {
  const resultsValues: number[] = [];
  let homologationResult = 0;
  let bestResult = 0;
  let htmlString = '';
  for (const res of results) {
    if (typeof res?.value === 'number') {
      if (!resultsValues.includes(+res.value)) resultsValues.push(+res.value);
    }
  }
  resultsValues.sort((a, b) => a - b);
  for (const res of results) {
    if (res?.['@_bestresult'] === '1') bestResult = res.value;
    for (const val of resultsValues) {
      if (res.value === val && +res?.wind <= 2) {
        homologationResult = +res.value;
      }
    }
  }
  for (let i = 0; i < results.length; i++) {
    let line = '';
    const value = typeof results[i]?.value === 'number' ? results[i].value.toFixed(2) : results[i]?.value;
    if (results[i]?.value === bestResult && results[i]?.value === homologationResult && results.length > 1) {
      line = `<strong style="text-decoration: underline">${value}</strong>`;
    } else if (results[i]?.value === bestResult && results.length > 1) {
      line = `<strong>${value}</strong>`;
    } else if (results[i]?.value === homologationResult && results.length > 1) {
      line = `<span style="text-decoration: underline">${value}</span>`;
    } else {
      line = value;
    }
    if (i !== results.length - 1) {
      line += '<br />';
    }
    htmlString += line;
  }
  return htmlString;
}
