import type { Athlete } from '@prisma/client';
import { Roles } from '@beathletics/api-interfaces';

export class LoadAllAthletes {
  static readonly type = '[Athlete API] Get all athletes';
}

export class SelectAthlete {
  static readonly type = '[Athlete API] Select athlete';
  constructor(public athlete: Athlete) {}
}

export class LoadResultsOfTheAthlete {
  static readonly type = '[Athlete API] Load all results of the athlete';
  constructor(public liveId: string) {}
}

export class LoadResultsOfTheAthleteByRole {
  static readonly type = '[Athlete API] Load results of the athlete by role';
  constructor(
    public liveId: string,
    public role: Roles,
  ) {}
}

export class DeleteAthlete {
  static readonly type = '[Athlete API] Delete athlete';
  constructor(public liveId: string) {}
}

// => NEW MODEL ACTIONS

export class SelectOrLoadAthleteWithResultsByLiveId {
  static readonly type = '[Athlete API] Load new athlete with results by liveId';
  constructor(public liveId: string) {}
}
