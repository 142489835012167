import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { produce } from 'immer';

import { Validation, Roles, ResultWithRelations } from '@beathletics/api-interfaces';
import { ResultApiService } from './result-api.service';
import {
  DeleteResult,
  LoadAllResults,
  SelectResult,
  UpdateResult,
  LoadAthleteOfTheResult,
  LoadEventOfTheResult,
  UpdateArrayOfResults,
  UpdateResultsValidation,
} from './result.action';
import { AuthState, AuthStateModel } from '@beathletics/auth';
import { ResultChanged } from '../event/event.action';

interface ResultStateData {
  resultIds: string[];
  results: {
    [id: string]: ResultWithRelations;
  };
  selectedResult: Partial<ResultWithRelations>;
}

@State<ResultStateData>({
  name: 'results',
  defaults: {
    resultIds: [],
    results: {},
    selectedResult: {},
  },
})
@Injectable()
export class ResultState {
  private apiService = inject(ResultApiService);
  private store = inject(Store);

  static validationStatus = [
    {
      name: 'En attente',
      value: Validation.Pending,
      role: Roles.Admin,
    },
    {
      name: 'Validé par le JA',
      value: Validation.JA_approved,
      role: Roles.Ja,
    },
    {
      name: 'Refusé par le JA',
      value: Validation.JA_not_approved,
      role: Roles.Ja,
    },
    {
      name: "Certifié par l'homologation",
      value: Validation.H_certified,
      role: Roles.Homologation,
    },
    {
      name: "Refusé par l'homologation",
      value: Validation.H_not_certified,
      role: Roles.Homologation,
    },
  ];

  @Selector()
  static allResults(state: ResultStateData) {
    return Object.keys(state.results).map((key) => state.results[key]);
  }

  @Selector()
  static athleteOfResult(state: ResultStateData) {
    if (state.selectedResult.athletes) {
      return state.selectedResult.athletes;
    } else return false;
  }

  @Selector()
  static eventOfResult(state: ResultStateData) {
    if (state.selectedResult.event) {
      return state.selectedResult.event;
    } else return false;
  }

  @Selector([ResultState, AuthState])
  static getValidationStatusFilterByRole(state: ResultStateData, authStateData: AuthStateModel) {
    if (authStateData.roles.includes(Roles.Admin_lbfa) || authStateData.roles.includes(Roles.Admin)) {
      return this.validationStatus;
    } else {
      return this.validationStatus.filter((status) => authStateData.roles.includes(status.role));
    }
  }

  @Action(LoadAllResults)
  loadAllResults(ctx: StateContext<ResultStateData>) {
    return this.apiService.getAllResults().pipe(
      tap((results) => {
        ctx.setState(
          produce((draft: ResultStateData) => {
            for (const r of results) {
              draft.results[r.id] = r;
            }
          }),
        );
      }),
    );
  }

  @Action(SelectResult)
  selectResult(ctx: StateContext<ResultStateData>, action: SelectResult) {
    return ctx.setState(
      produce((draft: ResultStateData) => {
        draft.selectedResult = action.result;
      }),
    );
  }

  @Action(LoadAthleteOfTheResult)
  loadAthleteOfTheResult(ctx: StateContext<ResultStateData>, action: LoadAthleteOfTheResult) {
    return this.apiService.getAthleteOfTheResult(action.athleteId).pipe(
      tap((athlete) => {
        ctx.setState(
          produce((draft: ResultStateData) => {
            draft.selectedResult.athletes = [athlete];
          }),
        );
      }),
    );
  }

  @Action(LoadEventOfTheResult)
  loadEventOfTheResult(ctx: StateContext<ResultStateData>, action: LoadEventOfTheResult) {
    return this.apiService.getEventOfTheResult(action.eventId).pipe(
      tap((event) => {
        ctx.setState(
          produce((draft: ResultStateData) => {
            draft.selectedResult.event = event;
          }),
        );
      }),
    );
  }

  @Action(UpdateResult)
  updateResult(ctx: StateContext<ResultStateData>, action: UpdateResult) {
    return this.apiService.updateResultData(action.result).pipe(
      tap(() => {
        this.store.dispatch(new ResultChanged(action.result));
      }),
    );
  }

  @Action(UpdateArrayOfResults)
  updateArrayOfResults(ctx: StateContext<ResultStateData>, action: UpdateArrayOfResults) {
    return this.apiService.updateArrayOfResults(action.results).pipe(
      tap(() => {
        ctx.setState(
          produce((draft: ResultStateData) => {
            for (const result of action.results) {
              draft.results[result.id] = result;
            }
          }),
        );
      }),
    );
  }

  @Action(UpdateResultsValidation)
  updateResultsValidation(ctx: StateContext<ResultStateData>, action: UpdateResultsValidation) {
    return this.apiService.updateMultipleValidationStatus(action.data).pipe(
      tap((results) => {
        ctx.setState(
          produce((draft: ResultStateData) => {
            for (const result of results) {
              draft.results[result.id] = result;
            }
          }),
        );
      }),
    );
  }

  @Action(DeleteResult)
  deleteResult(ctx: StateContext<ResultStateData>, action: DeleteResult) {
    return this.apiService.deleteResultData(action.id).pipe(
      tap(() => {
        ctx.setState(
          produce((draft: ResultStateData) => {
            delete draft.results[action.id];
          }),
        );
      }),
    );
  }
}
