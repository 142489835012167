import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Athlete, Results, Organization } from '@prisma/client';
import type { AthleteWithRelations, IAthlete } from '@beathletics/api-interfaces';

@Injectable({
  providedIn: 'root',
})
export class AthleteApiService {
  private http = inject(HttpClient);

  getAthleteDetail = (liveId: string) => this.http.get<AthleteWithRelations>(`/api/athlete/${liveId}`);

  getAthleteById = (id: string) => this.http.get<Athlete>(`/api/athlete/id/${id}`);

  getAllResultsOfAnAthlete = (liveId: string) => this.http.get<Results[]>(`/api/athlete/${liveId}/results`);

  getOrganizationOfAnAthlete = (orgaID: string) => this.http.get<Organization>(`/api/athlete/organization/${orgaID}`);

  deleteAthleteData = (liveId: string) => this.http.delete<Athlete>(`/api/athlete/${liveId}/delete`);

  // => NEW MODEL FUNCTIONS

  getAllAthletes = () => this.http.get<IAthlete[]>('/api/athlete/all');

  getAthleteWithResultsByLiveId = (liveId: string) => this.http.get<IAthlete>(`/api/athlete/new/${liveId}`);
}
