import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { produce } from 'immer';
import { AthleteWithRelations } from '@beathletics/api-interfaces/types';
import { AthleteApiService } from './athlete-api.service';
import {
  DeleteAthlete,
  LoadAllAthletes,
  LoadResultsOfTheAthlete,
  SelectAthlete,
  LoadResultsOfTheAthleteByRole,
  SelectOrLoadAthleteWithResultsByLiveId,
} from './athlete.action';
import { filterResultsByRole } from '../filters';
import { processResults, ResultService } from '../result/result.service';
import { IAthlete } from '@beathletics/api-interfaces';

export interface AthleteStateData {
  athleteIds: string[];
  athletes: {
    [id: string]: IAthlete;
  };
  athletesLoaded: boolean;
  selectedAthlete: Partial<AthleteWithRelations>;
  newAthletes: { [liveId: string]: IAthlete };
  selectedLiveId: string;
}

@State<AthleteStateData>({
  name: 'athletes',
  defaults: {
    athleteIds: [],
    athletes: {},
    athletesLoaded: false,
    selectedAthlete: {},
    newAthletes: {},
    selectedLiveId: '',
  },
})
@Injectable()
export class AthleteState {
  private apiService = inject(AthleteApiService);
  private resultService = inject(ResultService);

  @Selector()
  static selectedAthlete(state: AthleteStateData) {
    return state.selectedAthlete;
  }

  @Selector()
  static resultsOfTheAthlete(state: AthleteStateData) {
    if (state?.selectedAthlete?.results && state.selectedAthlete.results.length > 0) {
      return processResults(state.selectedAthlete.results);
    } else return [];
  }

  // => NEW MODEL SELECTORS

  @Selector()
  static allAthletes(state: AthleteStateData) {
    if (!state.athletesLoaded) {
      return undefined;
    } else
      return Object.keys(state.athletes)
        .map((key) => state.athletes[key])
        .sort((a, b) => ((a?.person?.lastName || 'z') > (b?.person?.lastName || 'z') ? 1 : -1));
  }

  @Selector()
  static newSelectedAthlete(state: AthleteStateData) {
    return state.newAthletes[state.selectedLiveId];
  }

  // => -------------------------------------------

  @Action(SelectAthlete)
  selectAthlete(ctx: StateContext<AthleteStateData>, action: SelectAthlete) {
    return ctx.setState(
      produce((draft: AthleteStateData) => {
        draft.selectedAthlete = action.athlete;
      }),
    );
  }

  @Action(LoadResultsOfTheAthlete)
  loadResultsOfTheAthlete(ctx: StateContext<AthleteStateData>, action: LoadResultsOfTheAthlete) {
    return this.apiService.getAllResultsOfAnAthlete(action.liveId).pipe(
      tap((results) => {
        ctx.setState(
          produce((draft: AthleteStateData) => {
            draft.selectedAthlete.results = results;
          }),
        );
      }),
    );
  }

  @Action(LoadResultsOfTheAthleteByRole)
  loadResultsOfTheAthleteByRole(ctx: StateContext<AthleteStateData>, action: LoadResultsOfTheAthleteByRole) {
    return this.apiService.getAllResultsOfAnAthlete(action.liveId).pipe(
      tap((results) => {
        ctx.setState(
          produce((draft: AthleteStateData) => {
            const filteredResults = filterResultsByRole(results, action.role);
            draft.selectedAthlete.results = filteredResults;
          }),
        );
      }),
    );
  }

  @Action(DeleteAthlete)
  deleteAthlete(ctx: StateContext<AthleteStateData>, action: DeleteAthlete) {
    return this.apiService.deleteAthleteData(action.liveId).pipe(
      tap(() => {
        ctx.setState(
          produce((draft: AthleteStateData) => {
            delete draft.athletes[action.liveId];
          }),
        );
      }),
    );
  }

  // => NEW MODEL ACTIONS

  @Action(LoadAllAthletes)
  loadAllAthletes(ctx: StateContext<AthleteStateData>) {
    ctx.patchState({ athletesLoaded: false });
    return this.apiService.getAllAthletes().pipe(
      tap((athletes) => {
        ctx.setState(
          produce((draft: AthleteStateData) => {
            draft.athletesLoaded = true;
            for (const a of athletes) {
              if (a.liveId) {
                draft.athletes[a.liveId] = a;
              }
            }
          }),
        );
      }),
    );
  }

  @Action(SelectOrLoadAthleteWithResultsByLiveId)
  selectOrLoadAthleteWithResultsByLiveId(
    ctx: StateContext<AthleteStateData>,
    action: SelectOrLoadAthleteWithResultsByLiveId,
  ) {
    if (ctx.getState().newAthletes?.[action.liveId]) {
      ctx.setState(
        produce((draft: AthleteStateData) => {
          draft.selectedLiveId = action.liveId;
        }),
      );
      return;
    } else {
      return this.apiService.getAthleteWithResultsByLiveId(action.liveId).pipe(
        tap((athlete) => {
          ctx.setState(
            produce((draft: AthleteStateData) => {
              draft.newAthletes[action.liveId] = athlete;
              draft.selectedLiveId = action.liveId;
            }),
          );
        }),
      );
    }
  }
}
