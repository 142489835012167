import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResultsValidation } from '@beathletics/api-interfaces';
import type { Athlete, Event, Results } from '@prisma/client';

@Injectable({
  providedIn: 'root',
})
export class ResultApiService {
  private http = inject(HttpClient);

  getAllResults = () => this.http.get<Results[]>('/api/result/all');

  getAthleteOfTheResult = (athleteId: string) => this.http.get<Athlete>(`/api/result/athlete/${athleteId}`);

  getEventOfTheResult = (eventId: string) => this.http.get<Event>(`api/result/event/${eventId}`);

  getResultsDataCounts = () => this.http.get(`api/results/count-data`);

  //! Attention, the bellow update functions are modified to update new resultS table
  updateResultData = (data: Results) => {
    return this.http.put<Results>(`/api/results/${data.id}/update`, data);
  };

  updateArrayOfResults = (results: Results[]) => this.http.put<Results[]>(`/api/results/update/many`, results);

  updateMultipleValidationStatus = (data: ResultsValidation[]) =>
    this.http.post<Results[]>(`/api/results/update-validation`, data);

  deleteResultData = (id: string) => this.http.delete<Results>(`/api/result/${id}/delete`);
}
