import type { Results } from '@prisma/client';
import { Roles, Validation } from '@beathletics/api-interfaces';

export function filterResultsByRole(results: Results[], role: Roles) {
  let filteredResults: Results[] = [];
  switch (role) {
    case Roles.User:
      filteredResults = results.filter((r) => r.validation === Validation.H_certified);
      break;
    case Roles.Secretary:
      filteredResults = results.filter(
        (r) => r.validation === Validation.JA_not_approved || r.validation === Validation.H_not_certified,
      );
      break;
    case Roles.Ja:
      filteredResults = results.filter((r) => r.validation === Validation.Pending);
      break;
    case Roles.Homologation:
      filteredResults = results.filter((r) => r.validation === Validation.JA_approved);
      break;
    default:
      filteredResults = results;
      break;
  }
  return filteredResults;
}
