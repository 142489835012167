import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DashboardEvents, DashboardEventsQuery } from '@beathletics/api-interfaces';
import type { Event, Results } from '@prisma/client';

@Injectable({
  providedIn: 'root',
})
export class EventApiService {
  private http = inject(HttpClient);

  getAllEvents = () => this.http.get<Event[]>('/api/event/all');

  getLastNDaysEvents = (dashboardQuery: DashboardEventsQuery) =>
    this.http.get<DashboardEvents>(`/api/event/dashboard`, {
      params: new HttpParams({ fromObject: dashboardQuery as any }),
    });

  getAllResultOfAnEvent = (eventNumber: string) => this.http.get<Results[]>(`/api/event/${eventNumber}/results`);

  getEventDetail = (eventNumber: string) => this.http.get<Event>(`/api/event/${eventNumber}`);

  getEventDetailWithXmlCheck = (eventNumber: string) =>
    this.http.get<{ event: Event; resultsFromXml: boolean }>(`/api/event/${eventNumber}/withXmlCheck`);

  getEventById = (id: string) => this.http.get<Event>(`api/event/id/${id}`);

  updateEventData = (data: Event) =>
    this.http.put<Event>(`/api/event/${data.eventNumber}/update`, {
      data,
    });

  deleteEventData = (eventNumber: string) => this.http.delete<Event>(`/api/event/${eventNumber}/delete`);

  deleteResultsOfEvent = (eventId: string) => this.http.delete<Results[]>(`/api/event/delete/results/${eventId}`);
}
