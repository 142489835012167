import { Injectable, inject } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { switchMap, tap } from 'rxjs/operators';
import { produce } from 'immer';

import { Roles, Validation, GridResult, EventWithRelations, DashboardEventsQuery } from '@beathletics/api-interfaces';
import type { Results, Event } from '@prisma/client';
import { EventApiService } from './event-api.service';
import { ApiServiceService } from '../api-service.service';
import {
  LoadAllEvents,
  UpdateEvent,
  DeleteEvent,
  LoadResultsOfTheEvent,
  SelectEvent,
  LoadDashboardEvents,
  DeleteResultsOfTheEvent,
  ResultChanged,
} from './event.action';
import { AuthState, AuthStateModel, AuthService } from '@beathletics/auth';
import { processResults } from '../result/result.service';

interface EventStateData {
  eventIds: string[];
  events: {
    [id: string]: EventWithRelations;
  };
  selectedEvent?: EventWithRelations;
  selectedEventResultsFromXml: boolean;
  resultOfSelectedEvents: { [eventNumber: string]: GridResult[] };
  dashboardEvents: {
    toImport: Event[];
    toTreat: Event[];
    done: Event[];
  };
}

@State<EventStateData>({
  name: 'events',
  defaults: {
    eventIds: [],
    events: {},
    resultOfSelectedEvents: {},
    selectedEvent: undefined,
    selectedEventResultsFromXml: false,
    dashboardEvents: {
      toImport: [],
      toTreat: [],
      done: [],
    },
  },
})
@Injectable()
export class EventState {
  private apiService = inject(EventApiService);
  private generalApiService = inject(ApiServiceService);
  private authService = inject(AuthService);

  static statusByRoles: { [KEY: string]: Validation[] } = {
    [Roles.User]: [Validation.H_certified],
    [Roles.Secretary]: [Validation.JA_not_approved, Validation.H_not_certified],
    [Roles.Ja]: [Validation.Pending],
    [Roles.Homologation]: [Validation.JA_approved],
    [Roles.Admin || Roles.Admin_lbfa]: [
      Validation.H_certified,
      Validation.H_not_certified,
      Validation.JA_approved,
      Validation.JA_not_approved,
      Validation.Pending,
    ],
  };

  @Selector()
  static allEvents(state: EventStateData) {
    return Object.keys(state.events).map((key) => state.events[key]);
  }

  @Selector()
  static selectedEvent(state: EventStateData) {
    return state.selectedEvent;
  }

  @Selector()
  static resultsOfSelectedEvent(state: EventStateData) {
    return state.selectedEvent?.results;
  }

  @Selector()
  static resultsOfTheEvent(state: EventStateData) {
    if (!state.selectedEvent) return [];
    if (state.selectedEvent.eventNumber) {
      const results = state.resultOfSelectedEvents[state.selectedEvent.eventNumber];
      return processResults(results);
    } else return [];
  }

  @Selector()
  static hasValidationProcessStarted(state: EventStateData) {
    if (!state.selectedEvent) return false;
    else {
      if (
        state.selectedEvent.eventNumber &&
        Array.isArray(state.resultOfSelectedEvents[state.selectedEvent.eventNumber])
      ) {
        const results = state.resultOfSelectedEvents[state.selectedEvent.eventNumber];
        for (const res of results) {
          if (res.validation !== Validation.Pending) return true;
        }
        return false;
      } else return false;
    }
  }

  @Selector([EventState, AuthState, EventState.resultsOfTheEvent])
  static resultsOfTheEventByRole(state: EventStateData, auth: AuthStateModel, results: Results[]) {
    if (results) {
      return results.filter((result) => {
        if (auth.roles.includes(Roles.Admin) || auth.roles.includes(Roles.Admin_lbfa)) {
          if (result.validation === Validation.Pending) return true;
          else return false;
        } else if (auth.roles.includes(Roles.Homologation)) {
          if (result.validation === Validation.JA_approved || result.validation === Validation.JA_not_approved)
            return true;
          else return false;
        } else if (auth.roles.includes(Roles.Ja)) {
          if (result.validation === Validation.Pending) return true;
          else return false;
        } else return false;
      });
    } else return [];
  }

  @Selector([EventState, AuthState, EventState.resultsOfTheEvent])
  static validatedResultsOfTheEventByRole(state: EventStateData, auth: AuthStateModel, results: Results[]) {
    if (results) {
      return results.filter((result) => {
        if (auth.roles.includes(Roles.Admin) || auth.roles.includes(Roles.Admin_lbfa)) {
          if (result.validation !== Validation.Pending) return true;
          else return false;
        } else if (auth.roles.includes(Roles.Homologation)) {
          if (result.validation === Validation.H_certified || result.validation === Validation.H_not_certified)
            return true;
          else return false;
        } else if (auth.roles.includes(Roles.Ja)) {
          if (result.validation === Validation.JA_approved || result.validation === Validation.JA_not_approved)
            return true;
          else return false;
        } else return false;
      });
    } else return [];
  }

  @Selector()
  static getDashboardEvents(state: EventStateData) {
    return state.dashboardEvents;
  }

  @Selector()
  static areSelectedEventResultsFromXml(state: EventStateData) {
    return state.selectedEventResultsFromXml;
  }

  @Action(ResultChanged)
  changeResult(ctx: StateContext<EventStateData>, action: ResultChanged) {
    const eventNumber = ctx.getState().selectedEvent?.eventNumber;
    if (eventNumber) {
      const results = ctx.getState().resultOfSelectedEvents[eventNumber];
      const resultToChange = results.findIndex((res) => res.id === action.result.id);
      if (resultToChange >= 0) {
        ctx.setState(
          produce((draft: EventStateData) => {
            draft.resultOfSelectedEvents[eventNumber][resultToChange] = action.result;
          }),
        );
      }
    }
  }

  @Action(LoadAllEvents)
  loadAllEvents(ctx: StateContext<EventStateData>) {
    return this.apiService.getAllEvents().pipe(
      tap((events) => {
        ctx.setState(
          produce((draft: EventStateData) => {
            for (const e of events) {
              if (e.eventNumber) {
                draft.events[e.eventNumber] = e;
              }
            }
          }),
        );
      }),
    );
  }

  @Action(LoadDashboardEvents)
  loadDashboardEvents(ctx: StateContext<EventStateData>) {
    return this.authService.getHigherRoleAndProfile().pipe(
      switchMap((user) => {
        let query = {
          nbOfDays:
            user.role === Roles.Ja || user.role === Roles.Secretary || user.role === Roles.Homologation ? 60 : 30,
          filterCanceled: true,
          role: user.role,
        };
        if (user.role === Roles.Ja || user.role === Roles.Secretary) {
          query = {
            ...query,
            userEmail: user.profile ? user.profile?.email : undefined,
          } as DashboardEventsQuery;
        }
        return this.apiService.getLastNDaysEvents(query);
      }),
      tap((response) => {
        return ctx.setState(
          produce((draft: EventStateData) => {
            draft.dashboardEvents.toImport = response.toImport.sort((a, b) => {
              return new Date(a.date_start).getTime() - new Date(b.date_start).getTime();
            });
            draft.dashboardEvents.toTreat = response.toTreat.sort((a, b) => {
              return new Date(a.date_start).getTime() - new Date(b.date_start).getTime();
            });
            draft.dashboardEvents.done = response.done.sort((a, b) => {
              return new Date(a.date_start).getTime() - new Date(b.date_start).getTime();
            });
          }),
        );
      }),
    );
  }

  @Action(SelectEvent)
  selectEvent(ctx: StateContext<EventStateData>, action: SelectEvent) {
    if (ctx.getState().events[action.eventNumber] && !action.forceLoading) {
      return ctx.setState(
        produce((draft: EventStateData) => {
          draft.selectedEvent = draft.events[action.eventNumber];
        }),
      );
    } else {
      return this.apiService.getEventDetailWithXmlCheck(action.eventNumber).pipe(
        tap(({ event, resultsFromXml }) => {
          return ctx.setState(
            produce((draft: EventStateData) => {
              draft.events[action.eventNumber] = event;
              draft.selectedEvent = draft.events[action.eventNumber];
              draft.selectedEventResultsFromXml = resultsFromXml;
              draft.eventIds.push(action.eventNumber);
            }),
          );
        }),
      );
    }
  }

  @Action(LoadResultsOfTheEvent)
  loadResultsOfTheEvent(ctx: StateContext<EventStateData>, action: LoadResultsOfTheEvent) {
    const results$ = this.generalApiService.getEventResultsWithRelations(action.eventNumber);
    return results$.pipe(
      tap((results) => {
        ctx.setState(
          produce((draft: EventStateData) => {
            draft.resultOfSelectedEvents[action.eventNumber] = results;
          }),
        );
      }),
    );
  }

  @Action(UpdateEvent)
  updateEvent(ctx: StateContext<EventStateData>, action: UpdateEvent) {
    return this.apiService.updateEventData(action.event).pipe(
      tap(() => {
        ctx.setState(
          produce((draft: EventStateData) => {
            if (action.event.eventNumber) draft.events[action.event.eventNumber] = action.event;
          }),
        );
      }),
    );
  }

  @Action(DeleteEvent)
  deleteEvent(ctx: StateContext<EventStateData>, action: DeleteEvent) {
    return this.apiService.deleteEventData(action.eventNumber).pipe(
      tap(() => {
        ctx.setState(
          produce((draft: EventStateData) => {
            delete draft.events[action.eventNumber];
          }),
        );
      }),
    );
  }

  @Action(DeleteResultsOfTheEvent)
  deleteResultsOfTheEvent(ctx: StateContext<EventStateData>, action: DeleteResultsOfTheEvent) {
    return this.apiService.deleteResultsOfEvent(action.eventId).pipe(
      tap(() => {
        ctx.setState(
          produce((draft: EventStateData) => {
            draft.resultOfSelectedEvents = {};
          }),
        );
      }),
    );
  }
}
